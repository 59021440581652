<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0">
            <div class="card-title">
              <h3 class="card-label">
                <i class="fa fa-fw fa-clock"></i> Mis Turnos
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="estaCargandoDatos">
      <div class="col-md-12">
        <div class="alert alert-light">
          <i class="fa fa-spinner fa-spin"></i> Cargando datos...
        </div>
      </div>
    </div>
    <div class="row" v-if="!estaCargandoDatos && localesTurnos.length == 0">
      <div class="col-md-12">
        <div
          role="alert"
          class="alert alert-custom alert-light-warning fade show"
        >
          <div class="alert-icon">
            <i class="fa fa-exclamation-triangle"></i>
          </div>
          <div class="alert-text">
            No hay turnos asociados a tu usuario. Quizá estés buscando la lista
            de locales asociados. Ir a
            <router-link to="/location/list/localVotacionUsuario"
              >Mis Locales</router-link
            >.
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!estaCargandoDatos && localesTurnos.length != 0">
      <div
        class="col-md-6 col-xl-4"
        v-for="local in localesTurnos"
        :key="local.key"
      >
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body d-flex flex-column">
            <h2 class="font-weight-bolder mb-3 pb-2 text-center border-bottom">
              <span
                class="label label-xl label-dot  mr-2"
                :class="['label-' + local.estilo]"
              ></span>
              Turno {{ local.turno }}
            </h2>
            <div class="flex-grow-1">
              <h5 class="font-weight-bolder mb-2 text-center">
                {{ local.nombre }}
              </h5>
              <h5 class="text-muted  text-center">
                <small>{{ local.direccion }}</small>
              </h5>
            </div>
            <div class="text-center">
              <div class="w-75 m-auto">
                <apexchart
                  :options="local.chartOptions"
                  :series="local.series"
                  type="radialBar"
                ></apexchart>
              </div>
            </div>
            <div class="pt-0">
              <p class="text-center font-weight-normal font-size-lg pb-0">
                {{ local.regionNombre }} <br />{{ local.subRegionNombre }}
              </p>
              <a
                href="#"
                class="btn btn-shadow-hover font-weight-bolder w-100 py-3"
                :class="['btn-' + local.estilo]"
                @click.prevent="doFichaSeguimientoElectoralNewTab(local)"
                ><i class="la la-clipboard-list"></i>Ir a la ficha</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "local-votacion-usuario-turno-list",
  components: {},
  props: {},
  data: () => ({
    locales: [],
    pagination: {
      pageSize: 10,
    },
    filter: {
      regionId: null,
      subRegionId: null,
      codigo: "",
      descripcion: "",
    },
    regiones: [],
    subRegiones: [],
    estaCargandoDatos: true,
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {
    ...mapGetters(["layoutConfig"]),
    localesTurnos: function() {
      if (!this.locales || this.locales.length == 0) return [];

      var result = [];

      for (let i = 0; i < this.locales.length; i++) {
        var local = this.locales[i];

        for (let t = 0; t < local.turnos.length; t++) {
          debugger;
          var turno = local.turnos[t];

          var localTurno = this.cloneObject(local);
          localTurno.turno = turno;
          localTurno.key = local.localVotacionId + "-" + turno;

          var avance = 0;
          var estilo = 0;
          switch (turno) {
            case 1:
              avance = localTurno.porcentajeAvanceTurno1;
              estilo = "primary";
              break;
            case 2:
              avance = localTurno.porcentajeAvanceTurno2;
              estilo = "danger";
              break;
            case 3:
              avance = localTurno.porcentajeAvanceTurno3;
              estilo = "success";
              break;
            case 4:
              avance = localTurno.porcentajeAvanceTurno4;
              estilo = "info";
              break;
            case 5:
              avance = localTurno.porcentajeAvanceTurno5;
              estilo = "warning";
              break;
          }

          localTurno.estilo = estilo;
          localTurno.series = [avance];

          localTurno.chartOptions = {
            chart: {
              height: "50px",
              type: "radialBar",
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: "65%",
                },
                dataLabels: {
                  showOn: "always",
                  name: {
                    show: false,
                    fontWeight: "700",
                  },
                  value: {
                    color: this.layoutConfig("colors.gray.gray-700"),
                    fontSize: "30px",
                    fontWeight: "700",
                    offsetY: 12,
                    show: true,
                  },
                },
                track: {
                  background: this.layoutConfig("colors.theme.light." + estilo),
                  strokeWidth: "100%",
                },
              },
            },
            colors: [this.layoutConfig("colors.theme.base." + estilo)],
            stroke: {
              lineCap: "round",
            },
            labels: ["Progress"],
          };

          result.push(localTurno);
        }
      }
      return result;
    },
  },
  methods: {
    doFichaSeguimientoElectoral: function(localTurno) {
      this.$router.push({
        name: "sheet-local-edit",
        params: {
          procesoElectoralId: this.$session.get("ProcesoElectoralId"),
          localVotacionId: localTurno.localVotacionId,
          turno: localTurno.turno,
        },
      });
    },
    doFichaSeguimientoElectoralNewTab: function(localTurno) {
      let routeData = this.$router.resolve({
        name: "sheet-local-edit",
        params: {
          procesoElectoralId: this.$session.get("ProcesoElectoralId"),
          localVotacionId: localTurno.localVotacionId,
          turno: localTurno.turno,
        },
      });

      window.open(routeData.href, "_blank");
    },
    handleAddEditLocalVotacionSaved: function(obj) {
      //alert(JSON.stringify(obj));
      this.fetchLocalesVotacion();
    },
    fetchLocalesVotacionUsuarioTurno: function() {
      var model = {
        ...this.pagination,
        ...this.filter,
        ProcesoElectoralId: this.$session.get("ProcesoElectoralId"),
        UsuarioId: this.$session.get("UsuarioId"),
      };

      //alert(JSON.stringify(model));

      this.$http
        .post(
          this.apiRoute("Location", "ListLocalesVotacionUsuarioTurno"),
          model
        )
        .then(function(response) {
          this.locales = response.body.data.items;
          this.pagination = response.body.data.pagination;
          this.estaCargandoDatos = false;
        });
    },
    fetchRegiones: function() {
      var modelRegion = {};
      this.$http
        .post(this.apiRoute("Region", "ListRegiones"), modelRegion)
        .then(function(response) {
          this.regiones = response.body.data.items;
        });
    },
    fetchSubRegiones: function(regionId) {
      this.$http
        .get(this.apiRoute("Region", "ListSubRegionesPorRegion"), {
          params: {
            RegionId: regionId,
          },
        })
        .then(function(response) {
          this.subRegiones = response.body.data.items;
        });
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {
    "filter.regionId": function(newValue, oldValue) {
      this.fetchSubRegiones(newValue);
    },
  },
  mounted: function() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mis Turnos" }]);
    this.fetchRegiones();
    this.fetchLocalesVotacionUsuarioTurno();
    this.activateActivityTracker();
  },
  destoyed: function() {},
};
</script>
